<template>
  <div>
    <div v-for="(expectedDamageReport, index) in expectedDamageReports">
      <div class="row">
        <div class="col-md-10">
          <form-expected-damage-report :data="expectedDamageReport"
                                       :disabled="true"
                                       :index="index"
                                       :show-errors="showErrors"
                                       @submit="updateExpectedDamageReport"/>
        </div>
        <div class="col-md-2">
          <button class="btn btn-primary" @click="removeExpectedDamageReport(index)"><i class="material-icons">close</i>
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <button class="btn btn-primary" @click="addExpectedDamageReport"><i class="material-icons">add</i> Toevoegen
        </button>
        <button class="btn btn-primary" @click="saveExpectedDamageReport"><i class="material-icons">save</i> Opslaan
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import validator from './expectedDamageReport.validator'

export default {
  components: {
    formExpectedDamageReport: () => import('./formExpectedDamageReport')
  },
  props: {
    type: {
      required: true,
    },
    dmg: {
      required: true
    }
  },
  watch: {
    dmg: {
      deep: true,
      handler(newV, oldV){
        // Map newV -> expectedDamageReport.validator
      }
    }
  },
  data() {
    return {
      expectedDamageReports: [],
      showErrors: false
    }
  },
  methods: {
    addExpectedDamageReport() {
      this.expectedDamageReports.push(Object.assign({}, validator))
    },
    removeExpectedDamageReport(index) {
      this.expectedDamageReports.splice(index, 1)
    },
    updateExpectedDamageReport({index, form}) {
      this.expectedDamageReports[index] = form
    },
    saveExpectedDamageReport(){
      let hasErrors = false
      this.expectedDamageReports.forEach(v => {
        let isValid = this.$v.validate(v)
        if(!isValid && !hasErrors) hasErrors = true
      })
      this.showErrors = hasErrors
    },
    // createExpectedDamageReport(purchase) {
    //   this.damages.forEach(({description, price}) => {
    //     if (!!description.value || price > 0) {
    //       this.$api.expectedDamageReport.create(
    //           this.type,
    //           description.value,
    //           purchase,
    //           price
    //       )
    //     }
    //   })
    // }
  }
}
</script>
